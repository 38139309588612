import { useGetCreditApplicationById } from '@src/api/credit-api'
import { Loader } from '@src/components'
import useRequiredDocumentsWithStatus from '@src/data/creditapp-hooks'
import {
  applicantBankAccountStatusMatch,
  applicantIncomeAccepted,
  areAllDocumentsReadyForCVT,
  bankStatementOrVoidCheckAreDeclined,
  hasApplicantCompletedFlinks,
  hasMissingDocuments,
} from '@src/data/creditapp-selectors'
import { ERequiredExternalStepStatus } from '@src/types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

const FlinksStatusPage = (): JSX.Element => {
  const { t } = useTranslation()
  const params = useParams()
  const id = params.id as string
  const navigate = useNavigate()
  const [shouldPoll, setShouldPoll] = useState(true)
  const [progression, setProgression] = useState<number>(1)
  const [creditApp, isLoadingApp] = useGetCreditApplicationById(id, shouldPoll, 14000)
  const [startTime] = useState(Date.now())

  const flinksCompleted = hasApplicantCompletedFlinks(creditApp)

  const requiredDocumentsWithStatus = useRequiredDocumentsWithStatus(creditApp?.requiredDocuments, creditApp?.documents)

  const isMissingDocs = hasMissingDocuments(creditApp)

  const allDocumentsReadyForCVT = areAllDocumentsReadyForCVT(requiredDocumentsWithStatus)

  const canSpecifyLoanDetails = allDocumentsReadyForCVT && creditApp?.applicant.areIncomesConfirmed

  useEffect(() => {
    const interval = setInterval(() => {
      const differenceInMillis = Date.now() - startTime
      const maxDelay = 2 * 60 * 1000 // 2 min

      let percentage = (differenceInMillis / maxDelay) * 100

      percentage = Math.floor(percentage)

      if (percentage > 100) percentage = 100
      else if (percentage < 1) percentage = 1

      setProgression(percentage)

      if (percentage === 100 || flinksCompleted) {
        setProgression(100)
        setShouldPoll(false)
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [flinksCompleted, progression, startTime])

  const [verifiedTitle, verifiedDescription] = useMemo((): string[] => {
    const verified: string = t('flinks.incomeVerified')
    const completeLoan: string = t('flinks.completeTheLoan')
    const requiredDocuments: string = t('flinks.requiredDocuments')
    const manualReview: string = t('flinks.manualReview')

    if (!flinksCompleted) return [t('flinks.processingDelay'), t('flinks.processingDelayMessage')]

    if (canSpecifyLoanDetails) return [t('common.goodNews'), `${verified} ${completeLoan}`]

    if (isMissingDocs) return [t('common.thankYou'), `${verified} ${requiredDocuments}`]

    return [t('common.thankYou'), `${verified} ${manualReview}`]
  }, [isMissingDocs, canSpecifyLoanDetails, flinksCompleted, t])

  if (!creditApp || isLoadingApp || (progression < 100 && !flinksCompleted)) {
    let title: string = t('common.withYouShortly')
    let description: string = t('common.pleaseWait')

    if (progression && applicantBankAccountStatusMatch(creditApp, ERequiredExternalStepStatus.WaitingForBank)) {
      title = t('flinks.accountRetrieved')
      description = t('flinks.processingInfomation')
    } else if (
      progression &&
      applicantBankAccountStatusMatch(creditApp, ERequiredExternalStepStatus.AnalysisInProgress)
    ) {
      title = t('flinks.incomeAnalysis')
      description = t('flinks.bankStementAnalysed')
    }

    return <Loader title={title} description={description} progression={progression} />
  }

  if (
    flinksCompleted &&
    (!creditApp.applicant.areFlinksIncomeMatchingDeclared ||
      bankStatementOrVoidCheckAreDeclined(creditApp) ||
      !applicantIncomeAccepted(creditApp) ||
      applicantBankAccountStatusMatch(creditApp, ERequiredExternalStepStatus.WaitingForClient))
  ) {
    return <Navigate to={`/creditapplication/${id}/incomes`} replace />
  }

  const handleContinue = () => {
    let url = `/creditapplication/${id}`

    if (canSpecifyLoanDetails) url += '/worksheet'
    else if (isMissingDocs) url += '/required-documents'

    navigate(url, { replace: true })
  }

  return (
    <main className="general-message" style={{ display: 'block', textAlign: 'center' }}>
      <h1 style={{ marginBottom: '1rem' }}>{verifiedTitle}</h1>
      <h3>{verifiedDescription}</h3>
      <button type="submit" className="btn primary-action-btn btn-blue btn-simple" onClick={handleContinue}>
        <span>{t('common.continue')}</span>
        <i className="fa-regular fa-arrow-right" />
      </button>
    </main>
  )
}

export default FlinksStatusPage
