import { Routes, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GetStart from '@src/containers/GetStart/GetStart'
import ErrorPage from '@src/containers/ErrorPage'
import Eligibility from '@src/containers/GetStart/PreconditionPage'
import RouteToStatePage from '@src/containers/ViewCreditApplication/RouteToStagePage'
import TermsAndConditionsFlinksPage from '../Flinks/TermsAndConditionsFlinksPage'
import FlinksIncomeValidationPage from '../Flinks/FlinksIncomeValidationPage'
import Apply from '../ApplicationForm/apply'
import CancelApplicationPage from '../CancelApplication/CancelApplicationPage'
import BadCustomerReviewPage from '../BadCustomerReviewPage/BadCustomerReviewPage'
import FailedIdentificationPage from '../Vouched/FailedIdentificationPage'
import PrivacyPolicy from '../Vouched/PrivacyPolicy'
import ConsignoCallBack from '../ConsignoCallBack'
import ContractWaitingPage from '../Contract/ContractWaitingPage'
import FlinksStatusPage from '../Flinks/FlinksStatusPage'

const TopLevelRoutes = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Routes>
      <Route path="/" element={<GetStart />} />
      <Route path="" element={<GetStart />} />
      <Route path="eligibility" element={<Eligibility />} />
      <Route path="digital-verification-policy" element={<PrivacyPolicy />} />
      <Route path="apply/:currentstep" element={<Apply />} />
      <Route path="/creditapplication/:id/failedid" element={<FailedIdentificationPage />} />
      <Route path="/creditapplication/:id/flinks/terms" element={<TermsAndConditionsFlinksPage />} />
      <Route path="/creditapplication/:id/cancel" element={<CancelApplicationPage />} />
      <Route path="/creditapplication/:id/flinks/validate" element={<FlinksIncomeValidationPage />} />
      <Route path="/creditapplication/:id/flinks/status" element={<FlinksStatusPage />} />
      <Route path="/creditapplication/:id/badreview" element={<BadCustomerReviewPage />} />
      <Route path="/creditapplication/:id/consignoCallBack/applicant" element={<ConsignoCallBack />} />
      <Route path="/creditapplication/:id/contract" element={<ContractWaitingPage />} />
      <Route path="/creditapplication/:id/*" element={<RouteToStatePage />} />
      <Route path="*" element={<ErrorPage title={t('common.notFound')} />} />
    </Routes>
  )
}

export default TopLevelRoutes
