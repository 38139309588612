import {
  EApplicationStep,
  Constants,
  EMPTY_ARRAY,
  EApplicantType,
  EProvince,
  EDocumentType,
  EDocumentStatus,
  ERequiredExternalStepStatus,
  EExternalStep,
} from '@src/types'

import {
  FilteredCreditApplication,
  CreditApplicationDocument,
  RequiredDocument,
  FilteredApplicant,
  RequiredDocumentWithStatus,
} from '@src/api/types/FilteredCreditApplication'

import calculateMonthlyAmount from './compute-selectors'

const APPLICANT_TYPE = EApplicantType.Applicant

export function findMinimumAgeRequirement(stateIso: EProvince) {
  switch (stateIso) {
    case EProvince.novaScotia:
    case EProvince.newBrunswick:
    case EProvince.newfoundland:
      return 19
    default:
      return 18
  }
}

export function isDocumentMatchingRequiredDoc(document: CreditApplicationDocument, reqDoc: RequiredDocument) {
  return (
    document.typeId === reqDoc.typeId &&
    document.applicantType === reqDoc.applicantType &&
    document.subKey === reqDoc.subKey
  )
}

export function hasMissingDocuments(creditApp: FilteredCreditApplication | null, forStep?: EApplicationStep): boolean {
  if (!creditApp) return false

  const reqDocs = forStep
    ? creditApp.requiredDocuments.filter((x) => x.requiredBeforeStep === forStep)
    : creditApp.requiredDocuments
  const docIgnored = new Set([EDocumentType.SignedCVT, EDocumentType.AuditCVT])
  return !reqDocs.every(
    (req) =>
      docIgnored.has(req.typeId) ||
      creditApp.documents.findIndex(
        (doc) => isDocumentMatchingRequiredDoc(doc, req) && !Constants.ExpectingDocumentStatuses.includes(doc.status),
      ) > -1,
  )
}
export function getLoanAmount(creditApp: FilteredCreditApplication) {
  if (creditApp && creditApp.finalDecision) {
    const amount = Math.min(creditApp.requestedLoanAmount, creditApp.finalDecision.maxAmountFinanced)
    return amount
  }
  return 0
}

export const getWorksheet = (creditApp: FilteredCreditApplication) => {
  if (creditApp.worksheet === null) {
    throw new Error('Worksheet is null')
  }
  return creditApp.worksheet
}

export function getMaxPaymentForRequestedAmount(creditApplication: FilteredCreditApplication | null) {
  if (creditApplication && creditApplication.prequalificationDecision) {
    const amount = Math.min(
      creditApplication?.requestedLoanAmount ?? Number.MAX_VALUE,
      creditApplication?.prequalificationDecision.maxLoanAmount ?? Number.MAX_VALUE,
    )
    return calculateMonthlyAmount(
      creditApplication.applicant.age >= Constants.UpperThresholdAgeForApplicant
        ? Constants.CorrectedMaxTerm
        : Constants.BaseMaxTerm,
      creditApplication?.prequalificationDecision.maxInterestRate > 0
        ? creditApplication?.prequalificationDecision.maxInterestRate
        : Constants.MaxRate,
      amount,
      Constants.FinanceFeeRateForRegular,
    )
  }
  return 0
}

export function getMinPaymentForRequestedAmount(creditApplication: FilteredCreditApplication | null) {
  if (creditApplication && creditApplication.prequalificationDecision) {
    const amount = Math.min(
      creditApplication?.requestedLoanAmount ?? Number.MAX_VALUE,
      creditApplication?.prequalificationDecision.maxLoanAmount ?? Number.MAX_VALUE,
    )
    return calculateMonthlyAmount(
      creditApplication.applicant.age >= Constants.UpperThresholdAgeForApplicant
        ? Constants.CorrectedMaxTerm
        : Constants.BaseMaxTerm,
      creditApplication?.prequalificationDecision.minInterestRate,
      amount,
      Constants.FinanceFeeRateForRegular,
    )
  }
  return 0
}
const stepsBeforeOrEqualCVT = new Set<EApplicationStep>([
  EApplicationStep.Credit,
  EApplicationStep.Finaning,
  EApplicationStep.CVT,
])

export function areAllDocumentsReadyForCVT(requiredDocumentsWithStatus: RequiredDocumentWithStatus[]): boolean {
  return requiredDocumentsWithStatus.every(
    (d) => !stepsBeforeOrEqualCVT.has(d.requiredBeforeStep) || d.status === EDocumentStatus.Approved,
  )
}
export function getPrequalifiedAmount(creditApp: FilteredCreditApplication) {
  return Math.min(creditApp.requestedLoanAmount, creditApp.prequalificationDecision?.maxLoanAmount ?? Number.MAX_VALUE)
}

export function mustAskApplicantSIN(filteredCreditApplication: FilteredCreditApplication) {
  return !filteredCreditApplication.applicant.hasSin && filteredCreditApplication.applicant.isCreditTwoYearsOrLess
}

export const hasSignedContract = (creditApp: FilteredCreditApplication | null): boolean => {
  if (!creditApp) return false

  return creditApp.documents.some(
    (x) =>
      x.typeId === EDocumentType.SignedCVT &&
      (x.status === EDocumentStatus.AwaitingApproval || x.status === EDocumentStatus.Approved),
  )
}

export const hasApprovedCVT = (creditApp: FilteredCreditApplication | null): boolean => {
  if (!creditApp) return false

  return creditApp.documents.some((x) => x.typeId === EDocumentType.SignedCVT && x.status === EDocumentStatus.Approved)
}

export function buildRequiredDocumentsWithStatus(
  requiredDocuments: RequiredDocument[] | undefined | null,
  documents: CreditApplicationDocument[] | undefined | null,
) {
  if (!requiredDocuments || !documents) return EMPTY_ARRAY

  const ret = [] as RequiredDocumentWithStatus[]
  requiredDocuments.forEach((req) => {
    const doc = documents.find((d) => isDocumentMatchingRequiredDoc(d, req))
    if (doc) ret.push({ ...req, status: doc.status, refusalReason: doc.refusalReason } as RequiredDocumentWithStatus)
    else ret.push({ ...req, status: EDocumentStatus.AwaitingDocument, refusalReason: null })
  })

  return ret
}

export function applicantFullName(applicant: FilteredApplicant): string {
  return `${applicant.firstName}\u00a0${applicant.lastName}`
}

export function hasApplicantSubmittedAllRequiredIncomeDocuments(
  reqDocWithStatus: RequiredDocumentWithStatus[],
): boolean {
  const applicantIncomeDocs = reqDocWithStatus.filter(
    (x) => x.applicantType === APPLICANT_TYPE && Constants.IncomeProofTypes.indexOf(x.typeId) > -1,
  )
  if (applicantIncomeDocs.length === 0) return false

  const missing = applicantIncomeDocs.filter((x) => Constants.ExpectingDocumentStatuses.indexOf(x.status) > -1)

  return missing.length === 0
}

export function hasApplicantCompletedFlinks(creditApp: FilteredCreditApplication | null) {
  if (creditApp) {
    const reqStep = creditApp.requiredExternalSteps.find(
      (x) =>
        x.applicantType === APPLICANT_TYPE &&
        x.externalStepId === EExternalStep.BankAccount &&
        x.status === ERequiredExternalStepStatus.Completed,
    )

    if (reqStep) return true
  }

  return false
}

export function bankStatementOrVoidCheckAreDeclined(creditApp: FilteredCreditApplication | null) {
  const requiredDocuments = buildRequiredDocumentsWithStatus(creditApp?.requiredDocuments, creditApp?.documents)
  const docTypeSet = new Set([
    EDocumentType.SixMonthsBankStatements,
    EDocumentType.VoidCheque,
    EDocumentType.ThreeMonthsPersonalBankStatements,
    EDocumentType.LessThanThreeMonthsPersonalBankStatements,
  ])
  return requiredDocuments.some((x) => docTypeSet.has(x.typeId) && x.status === EDocumentStatus.Refused)
}

export function applicantIncomeAccepted(creditApp: FilteredCreditApplication | null) {
  return (
    creditApp?.requiredExternalSteps.some(
      (x) =>
        x.applicantType === EApplicantType.Applicant &&
        x.externalStepId === EExternalStep.BankAccount &&
        x.status === ERequiredExternalStepStatus.Completed,
    ) && !bankStatementOrVoidCheckAreDeclined(creditApp)
  )
}

export function hasApplicantSubmittedFlinks(creditApp: FilteredCreditApplication | null) {
  const inProgress = creditApp?.requiredExternalSteps.some(
    (x) =>
      x.applicantType === EApplicantType.Applicant &&
      x.externalStepId === EExternalStep.BankAccount &&
      (x.status === ERequiredExternalStepStatus.WaitingForBank ||
        x.status === ERequiredExternalStepStatus.AnalysisInProgress),
  )

  return inProgress ?? false
}

export function areApplicantIncomesUnderReview(creditApp: FilteredCreditApplication): boolean {
  const areIncomesConfirmed = creditApp.applicant.areIncomesConfirmed

  return (
    !areIncomesConfirmed &&
    (hasApplicantSubmittedFlinks(creditApp) ||
      (creditApp.applicant.areFlinksIncomeMatchingDeclared && !bankStatementOrVoidCheckAreDeclined(creditApp)))
  )
}

export function areAllRequiredDocumentsApproved(requiredDocumentWithStatusList: RequiredDocumentWithStatus[]): boolean {
  const docIgnored = new Set([EDocumentType.SignedCVT, EDocumentType.AuditCVT])
  return requiredDocumentWithStatusList.every((d) => docIgnored.has(d.typeId) || d.status === EDocumentStatus.Approved)
}

export function applicantBankAccountStatusMatch(
  creditApp: FilteredCreditApplication | null,
  status: ERequiredExternalStepStatus,
) {
  const match = creditApp?.requiredExternalSteps.some(
    (x) =>
      x.applicantType === EApplicantType.Applicant &&
      x.externalStepId === EExternalStep.BankAccount &&
      x.status === status,
  )

  return match ?? false
}

export function applicantFlinksId(creditApp: FilteredCreditApplication | null) {
  if (creditApp) {
    const reqStep = creditApp.requiredExternalSteps.find(
      (x) => x.applicantType === EApplicantType.Applicant && x.externalStepId === EExternalStep.BankAccount,
    )

    if (reqStep) {
      return reqStep.externalServiceId
    }
  }
  return ''
}
