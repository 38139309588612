import { useTranslation } from 'react-i18next'
import { FormatCurrency } from '@src/services/Formatter'
import { useFormContext } from 'react-hook-form'
import { WorksheetForm } from '@src/containers/Worsheet/schema-hooks'
import { format } from 'date-fns'
import { useEffect, useMemo } from 'react'
import { StepStateUpdater } from '@src/components/Stepper/StepperStep'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import useTermPaymentsTable from '@src/containers/InitialApprobationPage/hooks'
import { EWorksheetStep } from '../hooks'

type Props = {
  creditApp: FilteredCreditApplication
  soonestDeliveryOn: Date
  setStepState: StepStateUpdater
  onSubmit: () => void
  setCurrentStep: (step: EWorksheetStep) => void
  isSubmitting: boolean
}

const SummaryStep = ({ creditApp, soonestDeliveryOn, setStepState, onSubmit, setCurrentStep, isSubmitting }: Props) => {
  const { getValues } = useFormContext<WorksheetForm>()

  const { amountRequested, term, firstPaymentOn, includeInsurance } = getValues()
  const { t } = useTranslation()

  const [monthlyPaymentPerMonths, isLoadingTerms] = useTermPaymentsTable(creditApp, {
    firstPaymentOn,
    activationDate: soonestDeliveryOn,
    requestedAmount: Number(amountRequested),
    includeInsurance,
  })

  const selectedTerm = useMemo(
    () => monthlyPaymentPerMonths.find((mp) => mp.term === term),
    [monthlyPaymentPerMonths, term],
  )

  useEffect(() => setStepState({ loading: isLoadingTerms }), [isLoadingTerms, setStepState])

  if (isLoadingTerms) return <div className="loader" />

  return (
    <div>
      <h3>{t('worksheet.summary.title')}</h3>
      <table className="list">
        <tbody>
          <tr className="list-item">
            <td>
              <h6>{t('worksheet.summary.amount')}</h6>
            </td>
            <td>
              <h5 className="strong text-end">{FormatCurrency(amountRequested)}</h5>
            </td>
            <td>
              <button
                className="btn-icon"
                onClick={() => setCurrentStep(EWorksheetStep.AmountRequested)}
                aria-label={t('common.modify')}
              >
                <i className="fa-light fa-pen" />
              </button>
            </td>
          </tr>
          <tr className="list-item">
            <td>
              <h6>{t('worksheet.summary.term')}</h6>
            </td>
            <td>
              <h5 className="strong text-end">{term}</h5>
            </td>

            <td>
              <button
                className="btn-icon"
                onClick={() => setCurrentStep(EWorksheetStep.PayOverTime)}
                aria-label={t('common.modify')}
              >
                <i className="fa-light fa-pen" />
              </button>
            </td>
          </tr>
          <tr className="list-item">
            <td>
              <h6>{t('worksheet.summary.term_amount')}</h6>
              {includeInsurance && (
                <div className="caption">
                  {t('worksheet.summary.protection', { insuranceAmount: FormatCurrency(selectedTerm?.insuranceDelta) })}
                </div>
              )}
            </td>
            <td>
              <h5 className="strong text-end">
                {FormatCurrency(
                  includeInsurance ? selectedTerm?.amountWithInsurance : selectedTerm?.amountWithoutInsurance,
                )}
              </h5>
            </td>
            <td>
              <button
                className="btn-icon"
                onClick={() => setCurrentStep(EWorksheetStep.PayOverTime)}
                aria-label={t('common.modify')}
              >
                <i className="fa-light fa-pen" />
              </button>
            </td>
          </tr>
          <tr className="list-item">
            <td>
              <h6>{t('worksheet.summary.interest_rate')}</h6>
            </td>
            <td>
              <h5 className="strong text-end">
                {t('worksheet.summary.interest', {
                  value: creditApp.finalDecision.interestRate,
                  count: selectedTerm?.term,
                })}
              </h5>
            </td>
            <td>
              <div style={{ minHeight: '3em', minWidth: '3em' }} />
            </td>
          </tr>
          <tr className="list-item">
            <td>
              <h6>{t('worksheet.summary.date')}</h6>
            </td>
            <td>
              <h5 className="strong text-end" style={{ minWidth: '6em' }}>
                {format(firstPaymentOn, 'PPP')}
              </h5>
            </td>
            <td>
              <button
                className="btn-icon"
                onClick={() => setCurrentStep(EWorksheetStep.FirstPayment)}
                aria-label={t('common.modify')}
              >
                <i className="fa-light fa-pen" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <button className="btn btn-blue" onClick={onSubmit} disabled={isLoadingTerms || isSubmitting}>
          {t('worksheet.submit')}
        </button>
      </div>
    </div>
  )
}

export default SummaryStep
