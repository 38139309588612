import SectionTitle from '@src/components/PageSectionTitle'
import { useTranslation } from 'react-i18next'

const CompletedAppPage = () => {
  const { t } = useTranslation()
  return (
    <main className="general-message">
      <SectionTitle title={t('common.goodNews')} />
      <div>
        <h3>{t('common.loanCreated')}</h3>
      </div>
    </main>
  )
}

export default CompletedAppPage
